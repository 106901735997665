<template>
  <Page title="Manage CMS">
    <v-row>
      <v-col cols="2">
        <v-card class="pb-4">
          <v-navigation-drawer floating permanent stateless value="true">
            <v-list dense>
              <v-list-item
                v-for="item in items"
                :key="item.title"
                :to="item.path"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
        </v-card>
      </v-col>

      <v-col cols="10">
        <router-view name="manage" />
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import ManageRoutes from '../routes'

export default {
  computed: {
    items() {
      return ManageRoutes[0].children
    }
  }
}
</script>
