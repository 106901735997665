import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Page',{attrs:{"title":"Manage CMS"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"2"}},[_c(VCard,{staticClass:"pb-4"},[_c(VNavigationDrawer,{attrs:{"floating":"","permanent":"","stateless":"","value":"true"}},[_c(VList,{attrs:{"dense":""}},_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,attrs:{"to":item.path}},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(item.name))])],1)],1)}),1)],1)],1)],1),_c(VCol,{attrs:{"cols":"10"}},[_c('router-view',{attrs:{"name":"manage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }